@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;500;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

h1,
h2,
h3,
.navbar {
  font-family: 'Alegreya Sans SC', sans-serif;
}

h4, h5, p {
  font-family:'Source Sans Pro', sans-serif;
}

h1 {
  font-weight: 700;
}

p{
  font-size:1.5em;
}

center h1 {
  padding-bottom:30px;
}

h4 {
  line-height:2em;
}

.navbar-brand {
  font-size: 2.5rem;
  font-weight: 700;
}

.collapse .navbar-nav {
  width: 100%;
}

.card-img-top {
  max-height: 150px;
}

.card {
  border: none;
}

#headerrow{
  padding-top:25px;
}

.card-title{
  padding-top:20px;
  padding-bottom:20px;
  font-weight:400;
  font-size:1.5em;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}

h3 {
  font-weight:600;
}

#impact{
  background-color:wheat;
}

#headerrow , .navbar
{
  background-color:#fea;
}

#headerrow
{
  padding-bottom:50px;
}

#impact div{
  padding:30px;
}

#howitworks
{
  padding-top: 40px;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  width: 200px;
  height: 45px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding: 10px;
  }

.button:hover {
  background-color: #2EE59D;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

#headerrow
{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.form-control:focus {
  border-color: #fea;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px wheat;
}